import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Map from '../components/Map'
const AboutPage = () => (
    <Layout>
      <SEO title="Location" />
      <Map/>
    </Layout>
  )
  
  export default AboutPage
  